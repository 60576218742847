.container {
    background-color: rgba(65,178,251,255);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 20px;    
  }
  
  .swiperContainer {
    display: flex;
    justify-content: space-between;
    width: 80%;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%; /* Roughly half of the swiperContainer's width */
    height: 100px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* cursor: pointer; */
    
  }
  .categoryTitle{
    font-size: 20px;
    text-align: center;
    font-weight: bold;
  }
  /* .slide:hover {
    transform: scale(1.05);
  } */
  
  .tierText {
    color: black;
    font-size: 24px;
    font-weight: bold;
  }
  
  .features {
    width: 80%;
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .featureContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #f1f1f1;
    justify-content: flex-start; /* Aligns children to the left */
}

.featureContainer:last-child {
    border-bottom: none;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px; /* Set a fixed width for the icons */
    margin-right: 10px; /* Adds some space between the icon and text */
}

.featureTextContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Allows the container to take up remaining space */
    justify-content: center;
}

.featureName {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}

.featureDescription {
    font-size: 16px;
    color: #777;
}


  
  .purchaseButton {
    padding: 15px 25px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 18px;
    color: black;
    transition: background 0.3s ease-in-out;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, background 0.3s ease-in-out;
  }
  
  .purchaseButton:hover {
    background: #e5e5e5;
    transform: scale(0.97);
  }
  
  .purchaseButton:active {
    background: #e5e5e5;
    transform: scale(0.94);
  }
  

  .billingToggle {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px; /* to provide some spacing from the "Single Plan" text */
    margin-bottom: 10px; /* to provide some spacing from the "Single Plan" text */
}

.billingToggle span {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid white;
    border-radius: 15px;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
}

.billingToggle span:hover {
    background-color: white;
    color: black;
}

.billingToggle span.active {
    background-color: white;
    color: black;
}
.actionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-top: 10px;
}

.logoutButton {
    padding: 5px 10px;
    border: 1px solid white;
    border-radius: 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: transparent;
    color: white;
    transition: background-color 0.3s, color 0.3s;
}

.logoutButton:hover {
    background-color: white;
    color: black;
}
